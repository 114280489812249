// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ObjectType<V = any> = Record<string, V>;
type Lat = number;
type Lng = number;
export enum TimeInfo {
    TimeInit = 1000, // ms.
    TimeScale = 1.25,
    TimeDelay = 250, // ms.
}

export enum FormEditMode {
    Create,
    Edit,
}

export type FormErrorMessages = { [key: string]: string };

export enum ApiLoadStatus {
    Idle = 'idle',
    Loading = 'loading',
    Success = 'success',
    Error = 'error',
}

export enum StatusOrder {
    Preparing = 'preparing',
    Shipping = 'shipping',
    Delivery = 'delivery',
    Delivered = 'delivered',
    Cancel = 'cancel',
    Exchange = 'exchange',
    Paid = 'paid',
    ExchangeCancel = 'exchange-cancel',
}

export enum OrderStatusId {
    PaidSuccess = 1,
    Preparing = 2,
    DeliveryBegin = 3,
    Shipping = 4,
    DeliverySuccess = 5,
    Cancel = 6,
    ExchangeProduct = 7,
}

export enum NumOfImage {
    MAX_IMAGES_PER_ROW = 40,
}

export type ComboBoxItem = {
    value: number;
    name: string;
};

export enum DevicePageType {
    AvailableDevicePage = 0,
    OccupiedDevicePage = 1,
    LostDevicePage = 2,
    AllDevicePage = 3,
}

export enum DataStatus {
    LoadSuccess = 0,
    LoadFailure = 1,
    DataInvalid = 2,
    DataLoading = 3,
}

export enum RegistrationStep {
    Terminal = 'Terminal',
    Profile = 'Profile',
    InputEmail = 'InputEmail',
    InputPhone = 'InputPhone',
}

export enum WritingStep {
    CHOOSE_GROUP = 'choose_group',
    CHOOSE_GROUP_WITHOUT_SELECT_IMG = 'choose_group_with_select_img',
    WRITING_POST = 'writing_post',
    WRITING_URL = 'writing_url',
}

export enum PostTypeConfig {
    Challenges = 'challenges',
    Posts = 'posts',
    fetchForMyChannel = 'fetchForMyChannel',
    fetchForPartner = 'fetchForPartner',
    fetchForHome = 'fetchForHome',
}

export enum ActionMethod {
    LikePost = 'LikePost',
    LikeComment = 'LikeComment',
    BookmarkPost = 'BookmarkPost',
}

export enum LengthNumberPhone {
    MinValue = 6,
    MaxValue = 18,
}

export enum LengthNickName {
    MinValue = 2,
}

export enum AbsoluteValue {
    Number = 0,
}

export enum IndexNotExist {
    DEFAULT = -1,
}

export const countryCodes = [
    { code: 'KR', name: '대한민국', dialCode: '+82', format: /^(?:\+82|0)?\d{9,10}$/ },
    { code: 'US', name: '미국', dialCode: '+1', format: /^(?:\+1|1)?\d{10}$/ },
    { code: 'GB', name: '영국', dialCode: '+44', format: /^(?:\+44|0)?\d{10}$/ },
    { code: 'AU', name: '호주', dialCode: '+61', format: /^(?:\+61|0)?\d{9}$/ },
    { code: 'VN', name: '베트남', dialCode: '+84', format: /^(?:\+84|0)?\d{9,10}$/ },
    // Add more countries as needed
];

export const listFlag: any = {
    '+82': 'KR',
    '+1': 'US',
    '+44': 'GB',
    '+93': 'AF',
    '+355': 'AL',
    '+213': 'DZ',
    '+1-684': 'AS',
    '+376': 'AD',
    '+244': 'AO',
    '+1-264': 'AI',
    '+672': 'AQ',
    '+1-268': 'AG',
    '+54': 'AR',
    '+374': 'AM',
    '+297': 'AW',
    '+61': 'AU',
    '+43': 'AT',
    '+994': 'AZ',
    '+1-242': 'BS',
    '+973': 'BH',
    '+880': 'BD',
    '+1-246': 'BB',
    '+375': 'BY',
    '+32': 'BE',
    '+501': 'BZ',
    '+229': 'BJ',
    '+1-441': 'BM',
    '+975': 'BT',
    '+591': 'BO',
    '+387': 'BA',
    '+267': 'BW',
    '+55': 'BR',
    '+673': 'BN',
    '+359': 'BG',
    '+226': 'BF',
    '+257': 'BI',
    '+855': 'KH',
    '+237': 'CM',
    '+238': 'CV',
    '+1-345': 'KY',
    '+236': 'CF',
    '+235': 'TD',
    '+56': 'CL',
    '+86': 'CN',
    '+57': 'CO',
    '+269': 'KM',
    '+242': 'CG',
    '+243': 'CD',
    '+506': 'CR',
    '+385': 'HR',
    '+53': 'CU',
    '+357': 'CY',
    '+420': 'CZ',
    '+45': 'DK',
    '+253': 'DJ',
    '+1-767': 'DM',
    '+1-809': 'DO',
    '+593': 'EC',
    '+20': 'EG',
    '+503': 'SV',
    '+240': 'GQ',
    '+291': 'ER',
    '+372': 'EE',
    '+268': 'SZ',
    '+251': 'ET',
    '+679': 'FJ',
    '+358': 'FI',
    '+33': 'FR',
    '+241': 'GA',
    '+220': 'GM',
    '+995': 'GE',
    '+49': 'DE',
    '+233': 'GH',
    '+350': 'GI',
    '+30': 'GR',
    '+299': 'GL',
    '+1-473': 'GD',
    '+590': 'GP',
    '+1-671': 'GU',
    '+502': 'GT',
    '+224': 'GN',
    '+245': 'GW',
    '+592': 'GY',
    '+509': 'HT',
    '+504': 'HN',
    '+852': 'HK',
    '+36': 'HU',
    '+354': 'IS',
    '+91': 'IN',
    '+62': 'ID',
    '+98': 'IR',
    '+964': 'IQ',
    '+353': 'IE',
    '+972': 'IL',
    '+39': 'IT',
    '+225': 'CI',
    '+1-876': 'JM',
    '+81': 'JP',
    '+962': 'JO',
    '+7': 'KZ',
    '+254': 'KE',
    '+686': 'KI',
    '+850': 'KP',
    '+965': 'KW',
    '+996': 'KG',
    '+856': 'LA',
    '+371': 'LV',
    '+961': 'LB',
    '+266': 'LS',
    '+231': 'LR',
    '+218': 'LY',
    '+423': 'LI',
    '+370': 'LT',
    '+352': 'LU',
    '+853': 'MO',
    '+261': 'MG',
    '+265': 'MW',
    '+60': 'MY',
    '+960': 'MV',
    '+223': 'ML',
    '+356': 'MT',
    '+692': 'MH',
    '+596': 'MQ',
    '+222': 'MR',
    '+230': 'MU',
    '+262': 'YT',
    '+52': 'MX',
    '+691': 'FM',
    '+373': 'MD',
    '+377': 'MC',
    '+976': 'MN',
    '+382': 'ME',
    '+1-664': 'MS',
    '+212': 'MA',
    '+258': 'MZ',
    '+95': 'MM',
    '+264': 'NA',
    '+674': 'NR',
    '+977': 'NP',
    '+31': 'NL',
    '+687': 'NC',
    '+64': 'NZ',
    '+505': 'NI',
    '+227': 'NE',
    '+234': 'NG',
    '+683': 'NU',
    '+389': 'MK',
    '+1-670': 'MP',
    '+47': 'NO',
    '+968': 'OM',
    '+92': 'PK',
    '+680': 'PW',
    '+970': 'PS',
    '+507': 'PA',
    '+675': 'PG',
    '+595': 'PY',
    '+51': 'PE',
    '+63': 'PH',
    '+48': 'PL',
    '+351': 'PT',
    '+1-787': 'PR',
    '+974': 'QA',
    '+40': 'RO',
    '+250': 'RW',
    '+290': 'SH',
    '+1-869': 'KN',
    '+1-758': 'LC',
    '+508': 'PM',
    '+1-784': 'VC',
    '+685': 'WS',
    '+378': 'SM',
    '+239': 'ST',
    '+966': 'SA',
    '+221': 'SN',
    '+381': 'RS',
    '+248': 'SC',
    '+232': 'SL',
    '+65': 'SG',
    '+421': 'SK',
    '+386': 'SI',
    '+677': 'SB',
    '+252': 'SO',
    '+27': 'ZA',
    '+34': 'ES',
    '+94': 'LK',
    '+249': 'SD',
    '+597': 'SR',
    '+46': 'SE',
    '+41': 'CH',
    '+963': 'SY',
    '+886': 'TW',
    '+992': 'TJ',
    '+255': 'TZ',
    '+66': 'TH',
    '+228': 'TG',
    '+690': 'TK',
    '+676': 'TO',
    '+1-868': 'TT',
    '+216': 'TN',
    '+90': 'TR',
    '+993': 'TM',
    '+1-649': 'TC',
    '+688': 'TV',
    '+256': 'UG',
    '+380': 'UA',
    '+971': 'AE',
    '+598': 'UY',
    '+998': 'UZ',
    '+678': 'VU',
    '+58': 'VE',
    '+84': 'VN',
    '+681': 'WF',
    '+967': 'YE',
    '+260': 'ZM',
    '+263': 'ZW',
};

export const safeTags: string[] = [
    'div',
    'span',
    'youtube',
    'p',
    'a',
    'b',
    'i',
    'strong',
    'em',
    'ul',
    'ol',
    'li',
    'br',
    'hr',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'pre',
    'blockquote',
    'code',
    'table',
    'thead',
    'tbody',
    'tr',
    'th',
    'td',
    'img',
    'abbr',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'bdi',
    'bdo',
    'caption',
    'cite',
    'col',
    'colgroup',
    'data',
    'datalist',
    'dd',
    'del',
    'dfn',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'footer',
    'header',
    'kbd',
    'main',
    'mark',
    'nav',
    'noscript',
    'output',
    'picture',
    'progress',
    'q',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'section',
    'small',
    'sub',
    'sup',
    'summary',
    'time',
    'track',
    'var',
    'video',
    'wbr',
];

export const safeAttributes: string[] = ['href', 'src', 'alt', 'title', 'style'];

export enum ResponseStatus {
    // Successful responses
    Success = 200, // OK
    Created = 201, // Successfully created
    Accepted = 202, // Accepted but not completed
    NonAuthoritativeInfo = 203, // Non-authoritative information
    NoContent = 204, // No content to send for this request
    ResetContent = 205, // Reset content
    PartialContent = 206, // Partial content

    // Redirection messages
    MultipleChoices = 300, // Multiple choices
    MovedPermanently = 301, // The resource has moved permanently
    Found = 302, // The resource has moved temporarily
    SeeOther = 303, // See other resource
    NotModified = 304, // Not modified since last request
    UseProxy = 305, // Use proxy advised
    SwitchProxy = 306, // Subsequent requests should use a different proxy
    TemporaryRedirect = 307, // Temporary redirect
    PermanentRedirect = 308, // Permanent redirect

    // Client error responses
    BadRequest = 400, // Bad request
    Unauthorized = 401, // Authentication is required
    PaymentRequired = 402, // Payment required
    Forbidden = 403, // Server refuses to respond to request
    NotFound = 404, // The requested resource was not found
    MethodNotAllowed = 405, // Method not allowed
    NotAcceptable = 406, // Not acceptable
    ProxyAuthenticationRequired = 407, // Proxy authentication required
    RequestTimeout = 408, // Request timed out
    Conflict = 409, // Conflict in the request
    Gone = 410, // The resource requested is no longer available
    LengthRequired = 411, // Content length not defined
    PreconditionFailed = 412, // Precondition failed
    PayloadTooLarge = 413, // Payload too large
    URITooLong = 414, // URI too long
    UnsupportedMediaType = 415, // Unsupported media type
    RangeNotSatisfiable = 416, // Range not satisfiable
    ExpectationFailed = 417, // Expectation failed
    ImATeapot = 418, // I'm a teapot (Easter egg status)
    MisdirectedRequest = 421, // Misdirected request
    UnprocessableEntity = 422, // Unprocessable entity
    Locked = 423, // Locked
    FailedDependency = 424, // Failed dependency
    TooEarly = 425, // Too early
    UpgradeRequired = 426, // Upgrade required
    PreconditionRequired = 428, // Precondition required
    TooManyRequests = 429, // Too many requests
    RequestHeaderFieldsTooLarge = 431, // Request header fields too large
    UnavailableForLegalReasons = 451, // Unavailable for legal reasons

    // Server error responses
    InternalServerError = 500, // Internal server error
    NotImplemented = 501, // Not implemented
    BadGateway = 502, // Bad gateway
    ServiceUnavailable = 503, // Service unavailable
    GatewayTimeout = 504, // Gateway timeout
    HTTPVersionNotSupported = 505, // HTTP version not supported
    VariantAlsoNegotiates = 506, // Variant also negotiates
    InsufficientStorage = 507, // Insufficient storage
    LoopDetected = 508, // Loop detected
    NotExtended = 510, // Not extended
    NetworkAuthenticationRequired = 511, // Network authentication required
}

export const MAX_CHAR_LIMIT = 255; //max character for the comment

export const CONST = {
    MARKER_DISPLAYABLE_MIN_ZOOM: 14,
};

export const MSG = {
    NAVER_MAP_ERROR: (method: string) => `지도를 사용하는데 오류가 발생했습니다. origin: ${method}`,
    NAVER_MAP_NOTFOUND: '위치를 찾을 수 없습니다.',
};

export type NaverMap = naver.maps.Map;
export type Coordinates = [Lat, Lng];

export enum REDUCER_TYPES {
    toggleModal = 'toggle_modal',
    setModalUser = 'set_modal_user',
}

export enum STORIES_REDUCER_TYPES {
    setStories = 'set_stories',
    setSingleStory = 'set_single_story',
    setNewStoriesBatch = 'set_new_story_batch',
    toggleLoading = 'toggle_loading',
    startTiming = 'start_timing',
}

export enum LOCAL_STORAGE_TYPES {
    IS_ALLOW_NOTIFICATIONS = 'IS_ALLOW_NOTIFICATIONS',
    DEVICE_TOKEN = 'DEVICE_TOKEN',
}
